import axios from '../axios';

export const all = (params = {}) => {
  return axios.get('/job-offers', { params });
};

export const show = (id, params = {}) => {
  return axios.get(`/job-offers/${id}`, { params });
};

export const create = (payload) => {
  return axios.post('/job-offers', payload);
};

export const update = (id, payload) => {
  return axios.post(`/job-offers/${id}`, payload);
};

export const destroy = (id) => {
  return axios.delete(`/job-offers/${id}`);
};

export const uploadBgImage = (payload) => {
  return axios.post('/general-information/job-offer-bg-image', payload);
};
